<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/Page-header";
import errorHandler from '../../../../helpers/errorHandler';

/**
 * Starter component
 */
export default {
  page: {
    title: "Session",
  },
  head() {
    return {
      title: `${this.title}`,
    };
  },
  async created(){
    // await this.$store.dispatch('video/getYoutubeVideoInfo',this.$route.params.id).then( async token =>{
    //     this.video.chapter_id = token.chapter_id;
    //     this.video.chapter = token.chapter;
    //     this.video.lesson = token.lesson;
    //     this.video.lesson_id = token.lesson_id;
    //     this.video.link = token.link;
    //     this.video.name = token.name;
    //     this.video.id = this.$route.params.id;
    // }).catch( err =>{
    //     errorHandler.methods.errorMessage(err);
    // })
  },
  data() {
    return {
      video:{
        id: null,
        chapter_id: null,
        lesson_id: null,
        name: null,
        link: null,
      },
      title: "Videos",
      items: [
        {
          text: "Manage",
          href: "/",
        },
        {
          text: "Videos",
          href: "/videos/my-sessions",
        },
        {
          text: "View",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
        <div class="col-lg-9">
            <div class="card">
                <div class="card-body">
                    <p class="sub-header font-18 text-center" v-if="video.chapter && video.lesson">
                    {{ video.chapter.name + ' - ' + video.chapter.number}} /
                    <code>{{ video.lesson.description + ' - ' + video.lesson.number}}</code>
                    </p>
                    <h2 class="mb-2 text-center">{{ video.name }}</h2>

                    <!-- 16:9 aspect ratio -->
                    <div class="embed-responsive embed-responsive-16by9">
                    <iframe
                        class="embed-responsive-item"
                        :src="video.link"
                        title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen
                    ></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </Layout>
</template>
